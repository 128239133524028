.high-contrast {
  background-color: black !important;
  color: yellow !important;
  font-weight: bold !important;

}

.high-contrast * {
  background-color: black !important;
  color: yellow !important;
  font-weight: bold !important;

}

.big-font {
  font-size: 102% !important;
}
.big-font * {
  font-size: 102% !important;
}
